.content {
  position: relative;
}
@media (max-width: 800px) {
  .content {
    margin-top: 170px;
  }
}


.welcome {
  margin-bottom: 10px;
}

.logo {
  width: 560px;
  margin-left: 8px;
}
.logo img {
  width: 100%;
}
.logo svg {
  fill: var(--color-text);
}
@media (max-width: 600px) {
  .logo {
    width: 95vw;
  }
}

.title {
  font-size: 1.7em;
  margin-top: 0px;
}