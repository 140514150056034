.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: -1;
  transition: opacity 800ms cubic-bezier(0,.58,.6,.99), z-index 400ms 400ms;
  background-color: black;
  pointer-events: none;
}

.wrapper[data-active='true'] {
  opacity: 1;
  z-index: 0;
  transition: opacity 800ms cubic-bezier(0,.58,.6,.99);
  pointer-events: all;
}

.content {
  width: 100%;
  height: 100%;
  max-width: 1700px;
  margin: auto;
  padding: 0;
  transition: opacity 200ms cubic-bezier(0,.58,.6,.99) 0ms;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.nav {
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.6em;
}

@media (max-width: 800px) {
  .nav {
    width: 100%;
  }
}

.navLink {
  margin: 10px auto;
}

.socials {
  margin: 0;
  padding: 0;
}
.socials svg {
  height: 24px;
  width: 24px;
  fill: var(--color-text);
}

.socials li {
  display: inline-block;
  margin: 10px;
}
.socials li:first-child { margin: 4px 4px 4px 0;}

.album {
  width: 50%;
  text-align: center;
  font-size: 1.4em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 800px) {
  .album {
    width: 100%;
  }
}


.albumArt {
  margin: 0 auto;
  max-width: 55%;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.albumArt img {
  max-width: 100%;
}
