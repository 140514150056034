@font-face {
  font-family: 'urania-czech';
  src: url('https://d1nbo7haysbnoj.cloudfront.net/fonts/urania-czech_v2.ttf');
}

.container {
  position: absolute;
  top: 25%;
  left: 46.5%;
  /* right: 0;
  margin: 0 auto; */
  /* width: 600px;
  height: 600px; */

  /* static */
  width: 82vh;
  height: 82vh;
  min-width: 500px;
  min-height: 500px;
  transform: translateY(-25%) scale(0.8) translateX(-46.5%);
  /* border: 2px solid red; */
}

@media (min-width: 1900px) {
  .container {
    min-width: 1000px;
    min-height: 1000px;
  }
}
@media (max-width: 1901px) {
  .container {
    min-width: 500px;
    min-height: 500px;
  }
}


@media (max-width: 800px) {
  /* .container {
    width: 600px;
    height: 1000px;
  }
  .container[data-debug='true'] {
    width: 600px;
    height: 600px;
    background-color: blue;
  } */
}
/* STATIC */
@media (max-width: 800px) {
  .container {
    margin: 0 !important;
    padding: 0 !important;
    box-sizing: border-box;

    min-width: 90vw;
    width: 90vw;
    min-height: auto;
    height: 53vh;
    max-height: 580px;
    top: auto;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) scale(1) translateX(-50%);
  }
}



.container canvas{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.mapLoading {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  padding: 0px;
  font-size: 0.8em;
  box-sizing: border-box;
  background-color: rgba(0,0,0,0.6);
  z-index: 1;
  visibility: visible;
  opacity: 1;
}
.mapLoading[data-resized="true"] {
  bottom: 112px;
  font-size: 0.6em;
}
@media (max-width: 800px) {
  .mapLoading[data-resized="true"] {
    top: 300px;
    bottom: auto;
  }
}
@media (max-width: 380px) {
  .mapLoading[data-resized="true"] {
    top: 320px;
    bottom: auto;
  }
}

.mapLoading[data-status='hidden'] {
  visibility: hidden !important;
  opacity: 0 !important;
}

.searchTrigger {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 160px;
  padding: 6px 10px;
  border-radius: 20px;;
  box-sizing: border-box;
  background-color: rgba(0,0,0,0.8);
  color: white;
  text-align: center;
  font-size: 1.2em;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  font-family: 'urania-czech';
}
.searchTrigger[data-active="true"] {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.searchTrigger[data-resized="true"] {
  top: 160px;
}


.test {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-50%);
  width: 80px;
}