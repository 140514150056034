@font-face {
  font-family: 'urania-czech';
  src: url('https://d1nbo7haysbnoj.cloudfront.net/fonts/urania-czech.ttf');
}

/* :root {
  touch-action: pan-x pan-y;
  height: 100%;
  touch-action: none;
} */


body {
  --color-text-brown: #78664E;
  --color-text: #EEDEC9;
  --color-primary: #bd0000;
  --color-primary-hover: #970000;

  margin: 0;
  color: var(--color-text);
  background-color: black;
  font-family: 'urania-czech';
}
@media (max-width: 800px) {
  body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    touch-action: pan-x pan-y;
  }
}


.password-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 1.3em;
  background-color: black;

  
}
.password-cover form {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  width: 300px;
}

.password-cover button, input {
  width: 100%;
  box-sizing: border-box;
}

.password-cover button {
  background-color: grey;
  color: white;
  border: none;
  outline: none;
}
.password-cover p {
  font-size: 0.6em;
  color: red;
}

.screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0,0,0,0.7);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  background-image: url('https://d1nbo7haysbnoj.cloudfront.net/images/a7x-store-exterior_v2.jpg');
}
@media (max-width: 800px) {
  .screen {
    background-image: url('https://d1nbo7haysbnoj.cloudfront.net/images/a7x-store-exterior_mobile_v3.jpg');
  } 
}

.screen__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

button {
  color: black;
  background-color: white;
  outline: none;
  border: none;
  font-weight: 800;
  padding: 8px 16px;
  font-family: 'urania-czech';
  cursor: pointer;
}

.btn--primary {
  color: var(--color-text);
  background-color: var(--color-primary);
}
.btn--primary:hover{
  background-color: var(--color-primary-hover);
}

.btn--lg {
  font-size: 1.2em;
  min-width: 120px;
}

.btn--paint {
  mask: url('https://d1nbo7haysbnoj.cloudfront.net/images/button-mask.svg') center center no-repeat;
  mask-size: 100% 100%;
  padding: 12px 16px 18px 16px;
}


.btn--circle {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.btn--circle--sm {
  width: 50px;
  height: 50px;
}

.btn--circle--md {
  width: 60px;
  height: 60px;
}


/* TYPE */
a {
  text-decoration: none;
  color: inherit;
}

.text--link {
  cursor: pointer;
  user-select: none;
}
.text--link:hover {
  color: var(--color-primary);
}

.text--lead {
  font-size: 1.3em;
}


/* FORMS */
input {
  font-size: 16px;
  outline: none;
  background-color: black;
  color: white;
  font-family: 'urania-czech';
  outline: none;
  border: 2px solid var(--color-text);
}
::placeholder {
  color: var(--color-text-brown);
}
.input-row {
  margin: 10px auto;
  width: 100%;
  box-sizing: border-box;
}
.input-row:last-child {
  margin-bottom: 0;
}
.input-row input,
.input-row select{ 
  font-size: 16px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

.input--outline {
  background: transparent;
  border: 2px solid white;
  outline: none;
  color: white;
}

.input-group {
  display: flex;
}
.input-group input {
  padding-left: 10px;
  font-size: 16px;
}
.input-group .btn {
  padding: 10px 8px 5px 8px;
  font-size: 0.8em;
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}


.autocomplete {
  position: relative;
  border: 2px solid white;
}

.autocomplete__results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  color: black;
  overflow-y: scroll;
  max-height: 200px;
  display: none;
  z-index: 2;
}

.autocomplete-result {
  padding: 15px;
  border-bottom: 1px solid grey;
}
.autocomplete-result:hover {
  background-color: black;
  color: white;
  cursor: pointer;
}


.autocomplete[data-active='true'] .autocomplete__results {
    display: block;
}

.autocomplete-result__subtitle {
  font-size: 0.8em;
  pointer-events: none;
}


#center-rack {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://d1nbo7haysbnoj.cloudfront.net/images/rack-test_desktop.png');
  pointer-events: none;
}



.map-marker {
  background-image: url('https://d1nbo7haysbnoj.cloudfront.net/images/markers/a7x-map-icon_indie_100.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.map-marker--indie {
  background-image: url('https://d1nbo7haysbnoj.cloudfront.net/images/markers/a7x-map-icon_indie_100.png');
}
.map-marker--standard {
  background-image: url('https://d1nbo7haysbnoj.cloudfront.net/images/markers/a7x-map-icon_standard_100.png');
}
.map-marker--germany {
  background-image: url('https://d1nbo7haysbnoj.cloudfront.net/images/markers/a7x-map-icon_guitar-center_100.png');
}

.map-marker--orange {
  background-image: url('https://d1nbo7haysbnoj.cloudfront.net/images/markers/a7x-map-icon_orange_100.png');
}

.map-marker--hot-topic {
  background-image: url('https://d1nbo7haysbnoj.cloudfront.net/images/markers/a7x-map-icon_hot-topic_100.png');
}
.map-marker--newbury-comics {
  background-image: url('https://d1nbo7haysbnoj.cloudfront.net/images/markers/a7x-map-icon_newbury_100_v2.png');
}
.map-marker--zia {
  background-image: url('https://d1nbo7haysbnoj.cloudfront.net/images/markers/a7x-map-icon_zia_100.png');
}
.map-marker--guitar-center {
  background-image: url('https://d1nbo7haysbnoj.cloudfront.net/images/markers/a7x-map-icon_guitar-center_100.png');
}
.map-marker--barnes-noble {
  background-image: url('https://d1nbo7haysbnoj.cloudfront.net/images/markers/a7x-map-icon_barnes-noble_100.png');
}
.map-marker--world-exclusive {
  background-image: url('https://d1nbo7haysbnoj.cloudfront.net/images/markers/a7x-map-icon_world-exclusive_100.png');
}

@media (max-width: 800px) {
  .mapboxgl-ctrl-top-right {
    transform: scale(0.8) !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
    padding-left: 20px;
  }
}




#welcome-logo {
  fill: var(--color-text);
}

.grecaptcha-badge {
	display: none !important;
}








.main-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
 
  overflow: hidden;
  user-select: none;
}

.scroll-wrapper {
  position: absolute;
  height: 100%;
  min-width: 100vw;
  box-sizing: border-box;
  cursor: grab;
  bottom: 0;
  user-select: none;
}

.layer-wrap {
  position: relative;
  height: 100vh;
  min-width: 100%;
  /* pointer-events: none; */
}

.layer1 {
  position: absolute;
  bottom: 0;
  /* height: 2000px;
  width: 100vw; */
  /* height: 100%; */
  /* pointer-events: none; */

  /* position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://d1nbo7haysbnoj.cloudfront.net/images/scene-bg.jpg'); */
}

@media (max-width: 800px) {
  .layer1 {
    background-image: url('https://d1nbo7haysbnoj.cloudfront.net/images/scene-bg_mobile.jpg');
  }
}

#photo-link {
  position: absolute;
  top: 24%;
  right: 18%;
  width: 12%;
  height: 40%;
  cursor: pointer;
}

#record-player-link {
  position: absolute;
  bottom: 18%;
  left: 33.5%;
  width: 7%;
  height: 20%;
  cursor: pointer;
}

@media (max-width: 800px) {
  #photo-link {
    position: absolute;
    top: 24%;
    right: 23%;
    width: 12%;
    height: 40%;
  }
  
  #record-player-link {
    position: absolute;
    bottom: 22%;
    left: 38%;
    width: 8%;
    height: 20%;
    cursor: pointer;
  }
}

.footer {
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
  color: var(--color-text);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}