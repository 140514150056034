.wrapper {
  background-color: white;
  color: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.wrapper input {
  background-color: white;
  color: black;
  border: 2px solid black;
}

.wrapper ::placeholder {
  color: black;
}

.wrapper button{
  background-color: black;
  color: white;
}

.content {
  max-width: 800px;
  margin: 80px auto;
}

.table {
  width: 100%;
}

.table th {
  text-align: left;
  padding: 10px;
}

.table td {
  border: 1px solid grey;
  padding: 10px;
}

.tableImgWrap {
  position: relative;
  width: 100px;
  height: 100px;
}

.tableImgWrap img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
}