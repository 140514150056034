.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);

  opacity: 0;
  pointer-events: none;
  z-index: -9999;
  transition: opacity 600ms ease;
}

.modal[data-active="true"] {
  opacity: 1;
  pointer-events: all;
  z-index: 2;
  transition: opacity 600ms ease;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.5em;
  cursor: pointer;
}
.close:hover {
  color: var(--color-primary);
}
@media (max-width: 600px) {
  .close {
    top: 12px;
    right: 12px;
  }
}
.modal[data-role='admin'] .close {
  color: black;
}

.box {
  position: relative;
  max-width: 700px;
  min-width: 400px;
  text-align: center;
  padding: 6px;
  box-sizing: border-box;

  background-color: black;
}

.modal[data-size='small'] .box {
  max-width: 400px;
  min-width: auto;
}

.modal[data-size='med'] .box {
  max-width: 500px;
  min-width: auto;
}
.modal[data-size='auto'] .box {
  min-width: auto;
  max-width: auto;
  width: auto;
}

@media (max-width: 600px) {
  .box {
    max-width: 90%;
    min-width: auto;
    width: 90%;
  }
}

.content {
  padding: 30px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  /* border: 1px solid white; */
  border-radius: 10px;
 
}
.modal[data-position="bottom"] .box {
  position: absolute;
  bottom: 60px;
}
@media (max-height: 673px) {
  .content {
    padding: 40px;
  }
}
@media (max-width: 600px) {
  .content {
    padding: 20px;
  }
}

.content h1,
.content h2,
.content h3,
.content h4 {
  margin-top: 0;
}

.modal[data-role='admin'] .content,
.modal[data-role='admin'] .box {
  background-color: white;
}

.modal[data-role='admin'] .box {
  max-width: 300px;
  min-width: auto;
}