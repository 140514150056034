.bar {
  position: relative;
  margin: 20px auto;
  border: 2px solid var(--color-text);
  width: 100%;
  padding: 4px;
  box-sizing: border-box;
  user-select: none;
  max-width: 400px;
}

.bar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--color-text);
  animation: loading 4.5s ease forwards;
}

@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 95%;
  }
}

.text {
  position: relative;
  color: var(--color-text);
  mix-blend-mode: difference;
  font-size: 0.7em;
  letter-spacing: 2px;
}