.title {
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  margin: auto;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
}
@media (max-width: 800px) {
  .title {
    width: 240px;
  }
}
.title img {
  width: 100%;
}
.title svg {
  height: 30px;
}

.navBtn {
  background-color: rgba(0,0,0,0.1);
  outline: none;
  border: none;
  color: black;
  cursor: pointer;
  border-radius: 50px;
  padding: 4px 8px;
  color: black;
}
@media (max-width: 800px) {
  .navBtn {
    background-color: rgba(255,255,255,0.3);
  }
}

/* .nabBtnBg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: white;
} */

.navBtn::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  transform: translateY(-50%) translateX(-50%);
  border: 1px solid black;
  pointer-events: none;
  border-radius: 50px;
}

.navBtn[data-active="true"] {
  background-color: rgba(255,255,255,0.3);
  color: white;
}

.navBtn[data-active="true"]::before {
  border: 1px solid white;
}

.navBtn[data-role="home"] {
  position: fixed;
  top: 20px;
  left: 20px;
}

.navBtn[data-role="menu"] {
  position: fixed;
  top: 20px;
  right: 20px;
}

.navBtn[data-role="art"] {
  position: fixed;
  bottom: 70px;
  left: 20px;
}

.navBtn[data-role="map"] {
  position: fixed;
  bottom: 70px;
  right: 20px;
}

.navBtn[data-shape="circle"] {
  border-radius: 50%;
  width: 45px;
  height: 45px;
}
.navBtn[data-shape="circle"] svg{
  width: 25px;
  height: 25px;
  fill: white;
}
.navBtn[data-shape="circle"]::before {
  border-radius: 50%;
}

