.wrapper {
  width: 100%;
  text-transform: uppercase;
  font-family: 'franklin-gothic';
}

.shareTrigger {
  font-size: 0.6em;
  margin: 5px auto 15px auto;
  text-align: center;
}
.shareTrigger button, 
.shareTrigger a {
  margin: 0 auto;
  padding: 8px 20px;
  max-width: 100px;
  border: none;
  outline: none;
}

.btnFacebook {
  color: white;
  background-color: #4267B2;
  border: none;
  outline: none;
  
  font-size: 1em;
  font-weight: 400;
  text-transform: uppercase;
}

.btnTwitter {
  color: white !important;
  background-color: #1DA1F2;
  border: none;
  outline: none;
  
  font-size: 1em;
  font-weight: 400;
  text-transform: uppercase;
}

.igShare {
  position: relative;
  width: 100%;
  margin: 8px auto;
  display: block;
  padding: 0;
  user-select: none;
  font-family: 'franklin-gothic';
  font-size: 1.4em;
  text-transform: uppercase;
}
.igShare a {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  color: white;
  margin-top: 2px;
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
}

.igShare button {
  display: none !important;
  position: relative;
  background: transparent;
  padding: 0 !important;
  border: none;
  width: 100%;
}
.igShare img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.igShare div {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  box-sizing: border-box;
  color: white;
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  border-radius: 50px;
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.igShareTouch button {
  display: flex !important;
}
.igShare a {
  display: none !important;
}

.sharing {
  position: fixed;
  top: 0;
  left: 0;
  font-weight: 900 !important;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);

  text-transform: uppercase;

  z-index: -10;
  opacity: 0;
  transition: opacity 500ms ease, z-index 0ms 500ms;
}
.sharing button, 
.sharing a {
  font-weight: 900 !important;
  text-transform: uppercase;
  outline: none;
  border: none;
  font-size: 1em;
  font-family: 'Futura', sans-serif;
}
.sharingActive {
  z-index: 10;
  opacity: 1;
  transition: opacity 500ms ease, z-index 0ms;
}

.sharingContent {
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.sharingContent svg {
  fill: white;
  color: white;
  height: 0.8em;
  margin-bottom: -2px;
}

.sharingContent button,
.sharingContent a {
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  border-radius: 50px;
  margin: 8px auto;
  padding: 8px 16px;
  box-sizing: border-box;
}

.sharingContent svg {
  margin-right: 8px;
}

.igShare {
  height: 30px;
}

.igShare button {
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  margin-top: -3px;
  padding: 0 !important;
}
.igsh button svg {
  margin-bottom: 1px;
}

.igShare a {
  margin-top: -1px;
}
.igShare a svg {
  margin-bottom: 0px;
}


.closeSharing {
  margin-bottom: 15px;
  cursor: pointer;
  user-select: none;
  color: white;
  cursor: pointer;
}
