.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
 
  overflow: hidden;
  user-select: none;
}

.scrollWrap {
  position: absolute;
  height: 100%;
  min-width: 100vw;
  box-sizing: border-box;
  cursor: grab;
}


.layer1 {
  position: relative;
  height: 100%;
  min-width: 100%;
  pointer-events: none;

  /* position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://d1nbo7haysbnoj.cloudfront.net/images/scene-bg.jpg'); */
}

@media (max-width: 800px) {
  .layer1 {
    background-image: url('https://d1nbo7haysbnoj.cloudfront.net/images/scene-bg_mobile.jpg');
  }
}