.webGlWrapper {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  pointer-events: none;
}

.webGlWrapper[data-pointer="false"] {
  pointer-events: none;
}