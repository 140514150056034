.wrapper {
  position: absolute;
  background-color: black;
  width: 200px;
  /* bottom: 10%;
  right: 32%;
  transform: translateX(-32%); */
  padding: 6px 10px 6px 12px;
  box-sizing: border-box;
  font-size: 0.9em;
  user-select: none;
  cursor: pointer;
}
.wrapper button {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2em;
}

@media (max-width: 800px) {
  .wrapper {
    position: absolute;
    /* margin-right: 0px; */
    /* right: 40px; */
    /* bottom: 200px;
    right: 38%;
    transform: translateX(-38%); */
  }
}

.form {
  visibility: visible;
  z-index: auto;
}
.form[data-submitted="true"] {
  visibility: hidden;
  z-index: -99;
}

.formStatus {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  visibility: hidden;
  font-size: 1.4em;
  margin: auto;
  z-index: -99;
}
.formStatus[data-submitted="true"] {
  visibility: visible;
  z-index: auto;
}

.pinConfirmation {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pinConfirmation hr {
  width: 200px;
}
.pinConfirmation p {
  max-width: 310px;
}